<template>
  <div class="p-4">
    <device-list></device-list>
  </div>
</template>
<script>
import DeviceList from './DeviceList.vue';

export default {
  components: {
    DeviceList,
  },
};
</script>
