<template>
  <!-- <a-col span="24" class="mt-4"> -->
  <a-row :gutter="[8, 8]" class="py-1 mb-2 px-1">
    <a-col span="12">
      <a-select
        v-model:value="selectedOrganizations"
        mode="multiple"
        style="width: 100%"
        placeholder="Select Organization(s)"
        :options="organizationOptions"
        :loading="isFetchingDevices"
        :filter-option="filterOption"
        :max-tag-count="4"
        id="analytics-trace-select-device-filter"
      >
      </a-select>
    </a-col>
    <a-col :autocave="2">
      <a-button
        id="all-org-select-button"
        @click="handleSelectAllOrganizations"
      >
        {{ isSelectedAllOrgs ? 'Cancel' : 'Select All' }}
      </a-button>
    </a-col>
    <a-col span="2">
      <a-button
        type="primary"
        @click="onUpdate"
        id="analytics-trace-update-btn"
      >
        Update
      </a-button>
    </a-col>

    <!-- </a-col> -->
    <a-col span="24" class="mt-4">
      <a-table
        :columns="columns"
        :data-source="list"
        bordered
        :pagination="pagination"
        @change="handleTableChange"
        :scroll="{ y: '50vh', x: '900px' }"
        class="w-100"
      >
        <template #title>
          <a-typography-title :level="5">
            Devices: &nbsp;
            <a-tag color="blue">{{ totalCount }}</a-tag>
          </a-typography-title>
        </template>
        <template #bodyCell="{ column , record }">
          <template v-if="column.dataIndex === 'visible_to_support_only'">
            <div class="actions">
              <span>
                <a-checkbox
                  v-model:checked="record.visible_to_support_only"
                  @change="handleCheckboxChange(record)"
                ></a-checkbox>
                <!-- <a-button type="primary" danger @click="deleteData(column.id)"
                  >Delete</a-button
                > -->
              </span>
            </div>
          </template>
        </template>
      </a-table>
    </a-col>
  </a-row>
</template>

<script>
import DeviceServices from '../../../../services/device';

export default {
  inject: ['toast'],
  data() {
    return {
      columns: [
        {
          title: 'ID',
          dataIndex: 'Device',
          width: '5%'
        },
        {
          title: 'SERIAL NUMBER',
          dataIndex: 'Serial_number',
          width: '15%'
        },
        {
          title: 'Display Name',
          dataIndex: 'display_name',
          width: '20%'
        },
        {
          title: 'GPU',
          dataIndex: 'gpu',
          width: '12%'
        },
        {
          title: 'RAM',
          dataIndex: 'ram',
          width: '6%'
        },
        {
          title: 'LAST ACTIVE',
          dataIndex: 'last_Active',
          width: '11%'
        },
        {
          title: 'ORGANIZATION',
          dataIndex: 'Organization',
          width: '13%'
        },
        {
          title: 'Support Only',
          dataIndex: 'visible_to_support_only',
          width: '10%',
          align: 'center'
        }
      ],
      list: [], // device list
      organizationList: [],
      selectedOrganizations: [],
      totalCount: 0,
      page: 1
    };
  },

  created() {
    this.getData();
    this.getOrganization();
  },

  computed: {
    organizationOptions() {
      return this.organizationList?.map(device => ({
        value: device.Org_name,
        label: device.Org_name
      }));
    },
    isSelectedAllOrgs() {
      return (
        this.selectedOrganizations.length === this.organizationOptions.length
      );
    },
    pagination() {
      return {
        position: ['bottomCenter'],
        showSizeChanger: false,
        current: this.page,
        total: this.totalCount
      };
    }
  },

  methods: {
    handleSelectAllOrganizations() {
      if (this.isSelectedAllOrgs) {
        this.selectedOrganizations = [];
        return;
      }
      const allOrgs = this.organizationOptions.map(org => org.value);
      this.selectedOrganizations = [...allOrgs];
    },
    async getData() {
      this.list = [];
      const query = this.getQueryParams();
      const [error, data] = await DeviceServices.getDeviceInfo(query);
      if (error) {
        console.log({ error });
        return;
      }
      this.totalCount = data.count;
      this.list = data.results;
    },

    async getOrganization() {
      this.organizationList = [];
      const [error, data] = await DeviceServices.getAllOrganizations();
      if (error) {
        console.log({ error });
        return;
      }
      this.organizationList = data;
    },

    onUpdate() {
      if (this.selectedOrganizations.length === 0)
        return this.toast.info('Organization is not selected');
      this.getData();
    },
    handleTableChange(pagination) {
      const { current } = pagination;
      this.page = current;
      this.getData();
    },
    getQueryParams() {
      let query =
        'organizations=' +
        this.selectedOrganizations.join() +
        '&page=' +
        this.page;
      return query;
    },
    async handleCheckboxChange(record) {
      let body = {
        Serial_number: record.Serial_number,
        visible_to_support_only: record.visible_to_support_only
      };

      const [error, data] = await DeviceServices.updateDevicePatch(
        record.Device,
        body,
        false
      );
      if (error) {
        console.log({ error });
        return;
      }
    }
  }
};
</script>

<style>
table,
tr,
td {
  text-align: left;
  margin-right: auto;
  margin-left: 0px;
  height: 15px;
  padding: 4px;
}
</style>
